import React, { useEffect, useState, useRef } from 'react';
import { Form, Select, Input, Spin, message, Space, Popover, Button } from 'antd';
import { DeliveredProcedureOutlined, CopyOutlined, ApiOutlined } from '@ant-design/icons';
import { ApiHelpers } from './';

const { Option } = Select;

const FormIntegrationSettings = (props) => {
    const {
        form,
        restaurantId,
        data,
        setIntegrationSettings,
        integrationSettings,
        restaurantDetails,
        setRestaurantDetails,
        onSaveRestaurantDetails,
    } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [integrationTypeList, setIntegrationTypeList] = useState([]);
    const [selectedIntegrationType, setSelectedIntegrationType] = useState('');
    const refMultiSoftKey = useRef(null);

    const tooltipCopyMultisoftKey = {
        title: '',
        text: <div>Copiaza in clipboard si foloseste in POS Multisoft</div>,
    };

    const INITIAL_STATE_INTEGRATION = {
        uniqueIdentificationToken: '',
        restaurantToken: restaurantId,
        freyaApiKey: null,
        endpoint: '',
        ebrizaClientId: '',
        ebrizaPublicKey: '',
        ebrizaSecret: '',
    };

    useEffect(() => {
        ApiHelpers.getAvailableIntegrations(restaurantId).then(
            (response) => {
                setIntegrationTypeList(response.data);
                if (restaurantDetails.sysPosIntegrationToken)
                    setSelectedIntegrationType(
                        response.data.filter((d) => d.uniqueIdentificationToken === restaurantDetails.sysPosIntegrationToken)[0].sysPosIntegrationName
                    );
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );

        form.setFieldsValue(integrationSettings);
    }, [restaurantId]);

    const onChangeSelect = (value, option) => {
        setSelectedIntegrationType(option.children);
        setRestaurantDetails({
            ...restaurantDetails,
            sysPosIntegrationToken: value,
        });
    };

    const onChange = (event) => {
        setIntegrationSettings({
            ...integrationSettings,
            [event.target.name]: event.target.value,
        });
    };

    const triggerIntegrationSync = () => {
        message.loading({ content: 'Se incarca...', key: 'statusMessage' });
        ApiHelpers.syncIntegration(restaurantId).then(
            (response) => {
                message.success({ content: 'Sincronizare finalizata cu succes', key: 'statusMessage', duration: 2 });
            },
            (error) => {
                message.error({ content: 'Eroare sincronizare!', key: 'statusMessage', duration: 2 });
                console.log(error);
            }
        );
    };

    return isLoading ? (
        <Spin />
    ) : (
        <div className="integration-selector">
            <Form.Item
                name="sysPosIntegrationToken"
                label={
                    <>
                        <span className="labelIcon">Sistem POS</span>
                    </>
                }
                rules={[
                    {
                        required: false,
                        message: 'Camp optional',
                    },
                ]}>
                <Select
                    size="large"
                    placeholder="Selecteaza sistem POS"
                    onChange={onChangeSelect}
                    name="sysPosIntegrationToken"
                    value={restaurantDetails ? restaurantDetails.sysPosIntegrationToken : ''}
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {integrationTypeList.map((d) => {
                        return (
                            <Option key={d.uniqueIdentificationToken} value={d.uniqueIdentificationToken}>
                                {d.sysPosIntegrationName}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
            {selectedIntegrationType && selectedIntegrationType.indexOf('Multisoft') === -1 ? (
                <Form.Item
                    name="endpoint"
                    label="Endpoint"
                    rules={[
                        {
                            required: true,
                            message: 'Camp obligatoriu',
                        },
                    ]}>
                    <Input size="large" onBlur={onChange} name="endpoint" value={integrationSettings.endpoint} />
                </Form.Item>
            ) : null}
            {selectedIntegrationType.indexOf('Freya') > -1 ? (
                <Form.Item
                    name="freyaApiKey"
                    label="Freya API Key"
                    rules={[
                        {
                            required: true,
                            message: 'Camp obligatoriu',
                        },
                    ]}>
                    <Input
                        size="large"
                        onBlur={onChange}
                        name="freyaApiKey"
                        value={restaurantDetails.integrationSettings ? restaurantDetails.integrationSettings.freyaApiKey : ''}
                    />
                </Form.Item>
            ) : selectedIntegrationType.indexOf('Ebriza') > -1 ? (
                <>
                    <Form.Item
                        name="ebrizaClientId"
                        label="Ebriza Client ID"
                        rules={[
                            {
                                required: true,
                                message: 'Camp obligatoriu',
                            },
                        ]}>
                        <Input
                            size="large"
                            onBlur={onChange}
                            name="ebrizaClientId"
                            value={restaurantDetails.integrationSettings ? restaurantDetails.integrationSettings.ebrizaClientId : ''}
                        />
                    </Form.Item>
                    <Form.Item
                        name="ebrizaPublicKey"
                        label="Ebriza Public Key"
                        rules={[
                            {
                                required: true,
                                message: 'Camp obligatoriu',
                            },
                        ]}>
                        <Input
                            size="large"
                            onBlur={onChange}
                            name="ebrizaPublicKey"
                            value={restaurantDetails.integrationSettings ? restaurantDetails.integrationSettings.ebrizaPublicKey : ''}
                        />
                    </Form.Item>
                    <Form.Item
                        name="ebrizaSecret"
                        label="Ebriza Secret Key"
                        rules={[
                            {
                                required: true,
                                message: 'Camp obligatoriu',
                            },
                        ]}>
                        <Input
                            size="large"
                            onBlur={onChange}
                            name="ebrizaSecret"
                            value={restaurantDetails.integrationSettings ? restaurantDetails.integrationSettings.ebrizaSecret : ''}
                        />
                    </Form.Item>
                </>
            ) : selectedIntegrationType.indexOf('Multisoft') > -1 ? (
                <Form.Item
                    name="multisoftKey"
                    label="Cheie"
                    rules={[
                        {
                            required: false,
                            message: 'Camp obligatoriu',
                        },
                    ]}>
                    {restaurantDetails.integrationSettings && restaurantDetails.integrationSettings.multisoftKey ? (
                        <Input
                            size="large"
                            disabled={true}
                            onBlur={onChange}
                            ref={refMultiSoftKey}
                            name="multisoftKey"
                            value={restaurantDetails.integrationSettings ? restaurantDetails.integrationSettings.multisoftKey : ''}
                            addonAfter={
                                <Popover
                                    className="iconSpacing"
                                    placement="right"
                                    title={tooltipCopyMultisoftKey.title}
                                    content={tooltipCopyMultisoftKey.text}
                                    trigger="hover">
                                    <Button
                                        icon={<CopyOutlined />}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                restaurantDetails.integrationSettings ? restaurantDetails.integrationSettings.multisoftKey : ''
                                            );
                                            message.success('Conținutul a fost copiat in clipboard');
                                        }}
                                    />
                                </Popover>
                            }
                        />
                    ) : (
                        <div className="actionLinkIcon">
                            <ApiOutlined />
                            <a onClick={() => onSaveRestaurantDetails(true)}>Genereaza</a>
                        </div>
                    )}
                </Form.Item>
            ) : null}
            {selectedIntegrationType.indexOf('Multisoft') === -1 ? (
                <div className="actionLinkIcon">
                    <DeliveredProcedureOutlined />
                    <a onClick={triggerIntegrationSync}>Sincronizeaza datele din POS</a>
                </div>
            ) : null}
        </div>
    );
};

export default FormIntegrationSettings;
