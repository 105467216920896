import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Table, Input, Select, Form, Drawer, Button, message, Checkbox, Popconfirm, Collapse, Tabs, Popover, Switch, Space } from 'antd';
import { PlusOutlined, EditOutlined, MenuOutlined, InfoCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { ApiHelpers } from '../../../helpers';
import { useNavigate, useLocation } from 'react-router-dom';
import * as ROUTES from '../../../const/routes';
import UploadMenuItemImage from '../Menu/UploadMenuItemImage';
import MenuItem from '../Menu/MenuItem';

import { activeRestaurantState, savedMenuCategory } from '../../../recoil';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const { TextArea } = Input;
const { Option } = Select;
const Search = Input.Search;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const tooltipImage = {
    title: '',
    text: <div>Dimensiunea recomandata de minim 1200x800px. Imaginea va fi ajustata pentru aspectul 3:2.</div>,
};

function MenuItemForm(props) {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);

    const [form] = Form.useForm();
    const [newElement, setNewElement] = useState([]);
    const [actionType, setActionType] = useState('');
    const [menuItemsData, setMenuItemsData] = useState([]);
    const [menuItemsDataFilter, setMenuItemsDataFilter] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState();
    const [menuItemFormList, setMenuItemFormList] = useState([]);
    const [menuItemGroups, setMenuItemGroups] = useState([]);
    const [integrationMenuItems, setIntegrationMenuItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tableIsLoading, setTableIsLoading] = useState(true);
    const [tablePagination, setTablePagination] = useState({
        disabled: false,
        pageSize: 50,
    });
    const INITIAL_STATE_TAXONOMY = {
        ingredients: [],
        englishIngredients: [],
        allergens: [],
        englishAllergens: [],
        foodAdditives: [],
    };
    const [taxonomyList, setTaxonomyList] = useState({ ...INITIAL_STATE_TAXONOMY });
    const [newTaxonomyList, setNewTaxonomyList] = useState({
        ingredients: [],
        englishIngredients: [],
        allergens: [],
        englishAllergens: [],
        foodAdditives: [],
    });
    let taxonomyContainer = {};
    const [lastMenuCategory, setLastMenuCategory] = useRecoilState(savedMenuCategory);
    const { tableFilter, setTableFilter } = props;
    const [filteredInfo, setFilteredInfo] = useState({});
    const routeLocation = useLocation();
    const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
    const SortableItem = sortableElement((props) => <tr {...props} className="ant-table-row" />);
    const SortableContainer = sortableContainer((props) => <tbody {...props} />);

    const callbackTabs = (key) => {
        setActiveTabKey(key);
    };
    const INITIAL_STATE_ELEMENT = {
        title: '',
        uniqueIdentificationToken: null,
        menuCategoryIdentificationToken: '',
        menuIdentificationToken: props.menuId,
        description: '',
        itemImagePath: null,
        preparationTime: '',
        price: 0,
        ingredients: '',
        allergens: '',
        tags: '',
        suggestion: '',
        active: true,
        category: '',
        weight: 0,
        vegan: false,
        diet: false,
        suitableForFasting: false,
        freezedIngredientsUsed: false,
        spicy: false,
        groups: [],
        menuItemIndex: 0,
        englishTitle: null,
        englishDescription: null,
        englishIngredients: null,
        englishAllergens: null,
        englishSuggestion: null,
        calculatePriceByWeight: false,
        weightMultiplicationFactor: 0,
        energy: null,
        fat: 0,
        saturates: 0,
        carbohydrate: 0,
        sugars: 0,
        proteins: 0,
        salt: 0,
        foodAdditives: null,
    };

    const tooltipCalculatePriceByWeight = {
        title: '',
        text: <div>Pretul se va calcula in functie de gramaj. Formula de calcul este gramaj * pretul introdus mai jos.</div>,
    };

    const getMenuItems = () => {
        // Get Menu items
        ApiHelpers.getMenuItems(props.menuId).then(
            (response) => {
                setMenuItemsData(response.data);
                setMenuItemsDataFilter(response.data);
                setTableIsLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        // after load get Menu items
        if (props.menuId) getMenuItems();
        //getTaxonomyDefault("allergens");
        setTableFilter([]);
    }, [props.menuId]);

    useEffect(() => {
        if (props.menuData.restaurantIdentificationToken) {
            // Get Menu Item Groups by restaurant
            ApiHelpers.getMenuItemGroup(props.menuData.restaurantIdentificationToken).then(
                (response) => {
                    setMenuItemGroups(response.data);
                },
                (error) => {
                    console.log(error);
                }
            );

            ApiHelpers.getIntegrationProducts(props.menuData.restaurantIdentificationToken).then(
                (response) => {
                    setIntegrationMenuItems(response.data);
                    setIsLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [props.menuData.restaurantIdentificationToken]);

    // Sort functions
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(menuItemsData), oldIndex, newIndex).filter((el) => !!el);
            newData.map((d, index) => (d.menuItemIndex = index));

            ApiHelpers.updateMenuItems(newData).then(
                (response) => {
                    message.success('Modificari efectuate!');
                    setMenuItemsData(newData);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };

    const DraggableContainer = (props) => (
        <SortableContainer useDragHandle disableAutoscroll helperClass="rowDragging" onSortEnd={onSortEnd} {...props} />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = menuItemsData.findIndex((x) => x.uniqueIdentificationToken === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const onClose = () => {
        setDrawerVisible(false);
        onReset();
        setActiveTabKey('ro');
    };
    const onAddElement = (element) => {
        navigate(ROUTES.ADMIN_LOCATIONS + props.menuData.restaurantIdentificationToken + ROUTES.ADMIN_MENU_ITEM + 'new', {
            state: { menuId: props.menuId, actionType: 'new' },
        });
        // setActionType('new');
        // // Get generated UUID from API and store it in state
        // ApiHelpers.getUUID().then(
        //     (response) => {
        //         setNewElement({
        //             ...INITIAL_STATE_ELEMENT,
        //             uniqueIdentificationToken: response.data.uuid,
        //             menuCategoryIdentificationToken: lastMenuCategory !== '' ? lastMenuCategory : '',
        //         });
        //         if (lastMenuCategory !== '') {
        //             form.setFieldsValue({
        //                 menuCategoryIdentificationToken: lastMenuCategory,
        //             });
        //         }
        //         setDrawerVisible(true);
        //     },
        //     (error) => {
        //         console.log(error);
        //     }
        // );
    };

    const onEditElement = (record, contentType, drawerVisible = true) => {
        navigate(ROUTES.ADMIN_LOCATIONS + props.menuData.restaurantIdentificationToken + ROUTES.ADMIN_MENU_ITEM + record.uniqueIdentificationToken);
        /*
        setActionType('edit');
        if (routeLocation.hash.indexOf('#items/menuitem/') === -1) {
            navigate('#items/menuitem/' + record.uniqueIdentificationToken);
        }
        // Set form fields values
        const editObject = {
            ...record,
        };

        if (record.groups && record.groups.length > 0) {
            const newArr = record.groups.map((d) => {
                return { uniqueIdentificationToken: d };
            });
            setMenuItemFormList([...newArr]);
            refreshForm(newArr);
        }

        taxonomyContainer.ingredients = record.ingredients !== '' && record.ingredients !== null ? record.ingredients.split(',') : [];
        taxonomyContainer.allergens = record.allergens !== '' && record.allergens !== null ? record.allergens.split(',') : [];
        taxonomyContainer.englishIngredients =
            record.englishIngredients !== '' && record.englishIngredients !== null ? record.englishIngredients.split(',') : [];
        taxonomyContainer.englishAllergens =
            record.englishAllergens !== '' && record.englishAllergens !== null ? record.englishAllergens.split(',') : [];
        taxonomyContainer.foodAdditives = record.foodAdditives !== '' && record.foodAdditives !== null ? record.foodAdditives.split(',') : [];

        form.setFieldsValue({
            ...editObject,
            ingredients: taxonomyContainer.ingredients,
            allergens: taxonomyContainer.allergens,
            englishIngredients: taxonomyContainer.englishIngredients,
            englishAllergens: taxonomyContainer.englishAllergens,
            foodAdditives: taxonomyContainer.foodAdditives,
        });
        // Update state with the element being edited
        setNewElement({ ...editObject });
        if (drawerVisible) setDrawerVisible(true);
        */
    };

    const onCopyElement = (record, contentType, drawerVisible = true) => {
        navigate(
            ROUTES.ADMIN_LOCATIONS +
                props.menuData.restaurantIdentificationToken +
                ROUTES.ADMIN_MENU_ITEM +
                record.uniqueIdentificationToken +
                '/copy'
        );
    };

    const onSave = () => {
        // Validate form
        form.validateFields()
            .then(() => {
                let groupsArray = [];
                if (menuItemFormList.length > 0)
                    // Collect groups from state and convert to array of strings
                    groupsArray = menuItemFormList.map((d) => {
                        return d.uniqueIdentificationToken;
                    });
                if (actionType === 'new') {
                    onSaveSelectTaxonomy('ingredients', 'ro_RO');
                    onSaveSelectTaxonomy('englishIngredients', 'en_US');
                    ApiHelpers.postMenuItem({
                        ...newElement,
                        groups: [...groupsArray],
                        weightMultiplicationFactor: newElement.weight.replace(/[^0-9\.]/g, ''),
                    }).then(
                        (response) => {
                            if (response.data.message) {
                                if (response.data.message.indexOf('Title') > -1) {
                                    message.error('Eroare sursa de data. Un preparat cu aceeasi denumire exista deja.');
                                } else message.error('Eroare sursa de date.');
                            } else {
                                message.success('Element adaugat!');
                                setLastMenuCategory(newElement.menuCategoryIdentificationToken);
                                onReset();
                                setDrawerVisible(false);
                                getMenuItems();
                            }
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                } else if (actionType === 'edit') {
                    onSaveSelectTaxonomy('ingredients', 'ro_RO');
                    onSaveSelectTaxonomy('englishIngredients', 'en_US');

                    ApiHelpers.updateMenuItem({
                        ...newElement,
                        groups: [...groupsArray],
                        weightMultiplicationFactor: newElement.weight.replace(/[^0-9\.]/g, ''),
                    }).then(
                        (response) => {
                            if (response.data.message) {
                                message.error('Eroare sursa de date.');
                            } else {
                                message.success('Element modificat!');
                                getMenuItems();
                            }
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );

                    onReset();
                    setDrawerVisible(false);
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const onDelete = () => {
        ApiHelpers.deleteMenuItem(newElement.uniqueIdentificationToken, props.menuData.restaurantIdentificationToken).then(
            (response) => {
                if (response.data.statusCode.statusCode === 200) {
                    const newList = menuItemsData.filter(function (obj) {
                        return obj.uniqueIdentificationToken !== newElement.uniqueIdentificationToken;
                    });
                    setMenuItemsData(newList);
                    setMenuItemsDataFilter(newList);
                    message.success('Elementul a fost sters!');
                }
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
        onReset();
        setDrawerVisible(false);
    };

    const onChange = (event, numberValidation = false) => {
        let value = event.target.value;
        if (numberValidation === true) {
            value = value.replace(/[^0-9\.]/g, '');
        }
        setNewElement({
            ...newElement,
            [event.target.name]: value,
        });
    };

    const onChangeCheckbox = (event, field) => {
        setNewElement({
            ...newElement,
            [field]: event.target.checked,
        });
    };

    const onChangeSelect = (value) => {
        setNewElement({
            ...newElement,
            menuCategoryIdentificationToken: value,
        });
    };

    const onChangeSelectIntegration = (value) => {
        setNewElement({
            ...newElement,
            integrationCode: value,
        });
    };

    const onFocusSelectTaxonomy = (value, taxonomy, languageCode = 'ro_RO') => {
        ApiHelpers.getMenuTaxonomy(taxonomy, value, languageCode).then(
            (response) => {
                setTaxonomyList({ ...taxonomyList, [taxonomy]: response.data });
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const onChangeSelectTaxonomy = (value, taxonomy) => {
        if (value) {
            const latestValue = value[value.length - 1];
            const stringTaxonomy = value.map((d) => {
                return d;
            });
            if (taxonomyList[taxonomy].length > 0 && taxonomyList[taxonomy].filter((e) => e.title === latestValue).length <= 0) {
                if (newTaxonomyList[taxonomy].indexOf(latestValue) < 0)
                    setNewTaxonomyList({ ...newTaxonomyList, [taxonomy]: [...newTaxonomyList[taxonomy], latestValue] });
            }
            setNewElement({
                ...newElement,
                [taxonomy]: stringTaxonomy.join(),
            });
        }
    };

    const onChangeActive = (value, element) => {
        console.log(value, element);
    };

    const onSaveSelectTaxonomy = (taxonomy, languageCode) => {
        if (newElement[taxonomy]) {
            const taxonomyArray = newElement[taxonomy].split(',');
            taxonomyArray.forEach(function (element) {
                ApiHelpers.postMenuTaxonomy(taxonomy, element, languageCode).then(
                    (response) => {},
                    (error) => {
                        console.log(error);
                    }
                );
            });
        }
    };

    const onReset = () => {
        form.resetFields();
        navigate('#items');
        setTaxonomyList({ ...INITIAL_STATE_TAXONOMY });
        setNewTaxonomyList({ ...INITIAL_STATE_TAXONOMY });
        setMenuItemFormList([]);
        setNewElement({ ...INITIAL_STATE_ELEMENT });
        taxonomyContainer = {};
        form.setFieldsValue({
            ingredients: [],
            allergens: [],
            englishIngredients: [],
            englishAllergens: [],
        });
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setMenuItemsData(
                menuItemsDataFilter.filter((item) => {
                    if (item.title.toLowerCase().indexOf(value) > -1 || item.category.toLowerCase().indexOf(value) > -1) return true;
                })
            );
        } else setMenuItemsData(menuItemsDataFilter);
    };

    const menuCategoryList = props.menuCategoriesData.map((d) => ({
        text: d.title,
        value: d.uniqueIdentificationToken,
    }));

    const navigate = useNavigate();
    const handleTableChange = (pagination, filters, sorter) => {
        setTableFilter(filters);
        //console.log(tableFilter ? tableFilter.category : null + 'Various parameters', pagination, filters, sorter);
        setTablePagination({ ...pagination });
        navigate('#items');
    };

    useEffect(() => {
        if (routeLocation.hash.indexOf('category=') > -1) {
            const menuCategory = routeLocation.hash.substring(routeLocation.hash.indexOf('category=') + 9);
            setLastMenuCategory(menuCategory);
            setTableFilter({ category: [menuCategory] });
        }
        if (routeLocation.hash.indexOf('/menuitem/') > -1) {
            const hashMenuItem = routeLocation.hash.substring(routeLocation.hash.indexOf('category=') + 17);
            const foundMenuItem = menuItemsData.find((d) => d.uniqueIdentificationToken === hashMenuItem);
            if (foundMenuItem) {
                onEditElement(foundMenuItem, 'menuCategory');
            }
        }
    }, [routeLocation, menuItemsData]);

    const addMenuItemFormCombo = () => {
        setMenuItemFormList((menuItemFormList) => [
            ...menuItemFormList,
            {
                uniqueIdentificationToken: null,
            },
        ]);
    };

    const refreshForm = (data) => {
        data.map((d, index) => {
            const menuItem = 'menuItem-' + index;
            form.setFieldsValue({
                [menuItem]: d.uniqueIdentificationToken,
            });
        });
    };

    const columns = [
        {
            title: 'Sortare',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Imagine',
            dataIndex: 'itemImage',
            className: 'drag-visible',
            render: (text, record) =>
                record.itemImagePath !== '' && record.itemImagePath !== null ? (
                    <img src={ApiHelpers.getImageLink(record.itemImagePath)} className="thumbnailList" />
                ) : (
                    ''
                ),
        },
        {
            title: 'Denumire',
            dataIndex: 'title',
            render: (text, record) => text,
        },
        {
            title: 'Denumire POS',
            dataIndex: 'integrationCode',
            render: (text, record) =>
                integrationMenuItems.length > 0
                    ? integrationMenuItems.filter((d) => d.code === text).length > 0
                        ? integrationMenuItems.filter((d) => d.code === text)[0].name
                        : ''
                    : '',
        },

        {
            title: 'Actiuni',
            render: (text, record) => (
                <Space>
                    <Button type="default" className="tableAction" icon={<EditOutlined />} onClick={() => onEditElement(record, 'menuCategory')}>
                        Editează
                    </Button>
                    <Button type="default" className="tableAction" icon={<CopyOutlined />} onClick={() => onCopyElement(record, 'menuItem')}>
                        Copiază
                    </Button>
                </Space>
            ),
        },
        {
            title: 'Categorie',
            dataIndex: 'category',
            filters: menuCategoryList,
            filteredValue: tableFilter ? tableFilter.category : null,
            onFilter: (value, record) => record.menuCategoryIdentificationToken.indexOf(value) === 0,
        },
        {
            title: 'Pret',
            dataIndex: 'price',
            render: (text, record) => text.toFixed(2),
        },
        {
            title: 'Pret POS',
            dataIndex: 'integrationCode',
            render: (text, record) =>
                integrationMenuItems.length > 0 ? (
                    integrationMenuItems.filter((d) => d.code === text).length > 0 ? (
                        <span
                            className={
                                integrationMenuItems.filter((d) => d.code === text)[0].unitPriceWithVat !== record.price.toFixed(2)
                                    ? 'label-error'
                                    : ''
                            }>
                            {integrationMenuItems.filter((d) => d.code === text)[0].unitPriceWithVat}
                        </span>
                    ) : (
                        ''
                    )
                ) : (
                    ''
                ),
        },
        {
            title: 'Activ',
            dataIndex: 'active',
            render: (text, record) => <Switch defaultChecked={record.active} onChange={(checked) => onChangeSwitchActive(checked, record)} />,
        },
    ];

    const onChangeSwitchActive = (checked, record) => {
        ApiHelpers.changeMenuItemState(props.menuData.restaurantIdentificationToken, record, checked).then(
            (response) => {
                if (response.data.message) {
                    message.error('Eroare sursa de date.');
                } else {
                    message.success('Element modificat!');
                    getMenuItems();
                }
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
    };

    return (
        <>
            <Button type="primary" onClick={() => onAddElement('menuCategory')} className="btnAdd">
                <PlusOutlined /> Adaugă preparat
            </Button>
            <Search placeholder="Cauta" onChange={handleSearch} className="tableSearchField" />
            <Table
                dataSource={menuItemsData}
                pagination={tablePagination}
                defaultPageSize={50}
                loading={tableIsLoading}
                rowKey="uniqueIdentificationToken"
                columns={!activeRestaurant.activatePosIntegration ? columns.filter((item) => item.dataIndex !== 'integrationCode') : columns}
                onChange={handleTableChange}
                scroll={{ x: 240 }}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}></Table>

            <Drawer
                title="Preparat"
                width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                onClose={onClose}
                visible={drawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
                destroyOnClose={true}
                footer={
                    <div className="modalFooter">
                        <div
                            style={{
                                textAlign: 'left',
                            }}>
                            {actionType !== 'new' ? (
                                <Popconfirm title="Sigur vrei sa stergi?" onConfirm={onDelete}>
                                    <Button size="large" danger style={{ marginRight: 8 }}>
                                        Sterge
                                    </Button>
                                </Popconfirm>
                            ) : null}
                        </div>
                        <div
                            style={{
                                textAlign: 'right',
                            }}>
                            <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                Anuleaza
                            </Button>
                            <Button size="large" onClick={onSave} type="primary">
                                Salveaza
                            </Button>
                        </div>
                    </div>
                }>
                <Form layout="vertical" form={form} size="large">
                    <Tabs defaultActiveKey="ro" activeKey={activeTabKey} onChange={callbackTabs} type="card">
                        <TabPane tab="RO" key="ro">
                            <Collapse className="formCollapse" bordered={false} defaultActiveKey={['info', 'media']}>
                                <Panel header="Informatii" key="info">
                                    <Form.Item
                                        name="menuCategoryIdentificationToken"
                                        label="Categorie"
                                        rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                                        <Select
                                            size="large"
                                            placeholder="Selecteaza categorie"
                                            onChange={onChangeSelect}
                                            name="menuCategoryIdentificationToken"
                                            value={newElement.menuCategoryIdentificationToken}>
                                            {props.menuCategoriesData.map((d) => (
                                                <Option key={d.uniqueIdentificationToken} value={d.uniqueIdentificationToken}>
                                                    {d.title}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="title" label="Denumire" rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                                        <Input placeholder="Please enter name" onBlur={onChange} name="title" value={newElement.title} />
                                    </Form.Item>

                                    <Form.Item name="description" label="Descriere" rules={[{ required: false }]}>
                                        <TextArea
                                            rows="5"
                                            placeholder="Please enter name"
                                            onBlur={onChange}
                                            name="description"
                                            value={newElement.description}
                                        />
                                    </Form.Item>

                                    <Form.Item name="tags" label="Etichete" rules={[{ required: false, message: '' }]}>
                                        <Checkbox checked={newElement.vegan} onChange={(event) => onChangeCheckbox(event, 'vegan')}>
                                            Vegan
                                        </Checkbox>
                                        <Checkbox checked={newElement.diet} onChange={(event) => onChangeCheckbox(event, 'diet')}>
                                            De dieta
                                        </Checkbox>
                                        <Checkbox
                                            checked={newElement.suitableForFasting}
                                            onChange={(event) => onChangeCheckbox(event, 'suitableForFasting')}>
                                            De post
                                        </Checkbox>
                                        <Checkbox
                                            checked={newElement.freezedIngredientsUsed}
                                            onChange={(event) => onChangeCheckbox(event, 'freezedIngredientsUsed')}>
                                            Contine produs congelat
                                        </Checkbox>
                                        <Checkbox checked={newElement.spicy} onChange={(event) => onChangeCheckbox(event, 'spicy')}>
                                            Picant
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="weight"
                                        label="Gramaj"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Camp obligatoriu',
                                            },
                                        ]}>
                                        <Input placeholder="Gramaj" onBlur={(event) => onChange(event)} name="weight" value={newElement.weight} />
                                    </Form.Item>
                                    <Form.Item name="calculatePriceByWeight" label="">
                                        <Checkbox
                                            checked={newElement.calculatePriceByWeight}
                                            onChange={(event) => onChangeCheckbox(event, 'calculatePriceByWeight')}>
                                            Pretul se calculeaza per gramaj
                                            <Popover
                                                className="iconSpacing"
                                                placement="right"
                                                title={tooltipCalculatePriceByWeight.title}
                                                content={tooltipCalculatePriceByWeight.text}
                                                trigger="hover">
                                                <InfoCircleOutlined />
                                            </Popover>
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="price"
                                        label="Pret (lei)"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Camp obligatoriu',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (value > 0) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('Pretul trebuie sa fie  > 0!');
                                                },
                                            }),
                                        ]}>
                                        <Input placeholder="Pret" onBlur={onChange} name="price" value={newElement.price} />
                                    </Form.Item>

                                    <Form.Item
                                        name="preparationTime"
                                        label="Timp de preparare"
                                        rules={[
                                            {
                                                required: false,
                                                message: '',
                                            },
                                        ]}>
                                        <Input placeholder="Minute" onBlur={onChange} name="preparationTime" value={newElement.preparationTime} />
                                    </Form.Item>
                                    <Checkbox checked={newElement.active} onChange={(event) => onChangeCheckbox(event, 'active')}>
                                        Activ
                                    </Checkbox>
                                </Panel>
                                <Panel header="Imagini" key="media">
                                    <Form.Item
                                        name="itemImagePath"
                                        label={
                                            <>
                                                Imagine
                                                <Popover
                                                    className="iconSpacing"
                                                    placement="right"
                                                    title={tooltipImage.title}
                                                    content={tooltipImage.text}
                                                    trigger="hover">
                                                    <InfoCircleOutlined />
                                                </Popover>
                                            </>
                                        }
                                        rules={[{ required: false, message: 'Camp obligatoriu' }]}>
                                        <UploadMenuItemImage
                                            menuItemsData={menuItemsData}
                                            setMenuItemsData={setMenuItemsData}
                                            getMenuItems={getMenuItems}
                                            menuItemId={newElement.uniqueIdentificationToken}
                                            newElement={newElement}
                                            setNewElement={setNewElement}
                                            restaurantIdentificationToken={props.menuData.restaurantIdentificationToken}
                                            menuId={props.menuId}
                                        />
                                    </Form.Item>
                                </Panel>

                                {!newElement.calculatePriceByWeight ? (
                                    <Panel header="Grupuri auxiliare" key="menuItemGroups">
                                        <Button type="secondary" onClick={() => addMenuItemFormCombo()} className="btnAdd">
                                            <PlusOutlined /> Adauga grup
                                        </Button>
                                        {menuItemFormList.map((item, index) => {
                                            return (
                                                <MenuItem
                                                    key={'menuItem' + index}
                                                    item={item}
                                                    index={index}
                                                    menuItems={menuItemGroups}
                                                    menuItemFormList={menuItemFormList}
                                                    setMenuItemFormList={setMenuItemFormList}
                                                    form={form}
                                                    refreshForm={refreshForm}
                                                />
                                            );
                                        })}
                                    </Panel>
                                ) : null}

                                {activeRestaurant.activatePosIntegration === true ? (
                                    <Panel header="Integrare POS" key="integrare">
                                        <Form.Item
                                            name="integrationCode"
                                            label={
                                                <>
                                                    <span className="labelIcon">Asociere preparat</span>
                                                </>
                                            }
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Camp optional',
                                                },
                                            ]}>
                                            <Select
                                                size="large"
                                                placeholder="Alege"
                                                onChange={onChangeSelectIntegration}
                                                name="integrationCode"
                                                value={newElement.integrationCode}
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {integrationMenuItems.map((d) => {
                                                    return (
                                                        <Option key={'integrationCode-' + d.code} value={d.code}>
                                                            {d.name + ' (' + d.unitPriceWithVat + ' lei)'}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Panel>
                                ) : null}
                            </Collapse>
                        </TabPane>
                        <TabPane tab="EN" key="en">
                            <Form.Item name="englishTitle" label="Denumire" rules={[{ required: false, message: 'Camp obligatoriu' }]}>
                                <Input placeholder="Please enter name" onBlur={onChange} name="englishTitle" value={newElement.englishTitle} />
                            </Form.Item>
                            <Form.Item name="englishDescription" label="Descriere" rules={[{ required: false }]}>
                                <TextArea
                                    rows="5"
                                    placeholder="Please enter name"
                                    onBlur={onChange}
                                    name="englishDescription"
                                    value={newElement.englishDescription}
                                />
                            </Form.Item>
                            <Form.Item
                                name="englishIngredients"
                                label="Ingrediente"
                                rules={[{ required: menuItemFormList.length > 0 ? false : true, message: 'Camp obligatoriu' }]}>
                                <Select
                                    mode="tags"
                                    name="englishIngredients"
                                    style={{ width: '100%' }}
                                    placeholder="Ingrediente"
                                    onChange={(value) => onChangeSelectTaxonomy(value, 'englishIngredients', 'en_US')}
                                    onFocus={(value) => onFocusSelectTaxonomy(value, 'englishIngredients', 'en_US')}>
                                    {taxonomyList.englishIngredients.length > 0
                                        ? taxonomyList.englishIngredients.map((d, index) => {
                                              return (
                                                  <Option key={index} value={d.title}>
                                                      {d.title}
                                                  </Option>
                                              );
                                          })
                                        : null}
                                </Select>
                            </Form.Item>
                            <Form.Item name="englishAllergens" label="Alergeni" rules={[{ required: false }]}>
                                <Select
                                    mode="multiple"
                                    name="allergens"
                                    style={{ width: '100%' }}
                                    placeholder="Alergeni"
                                    disabled
                                    onChange={(value) => onChangeSelectTaxonomy(value, 'englishAllergens', 'en_US')}
                                    onFocus={(value) => onFocusSelectTaxonomy(value, 'englishAllergens', 'en_US')}
                                    filterOption={(inputValue, option) =>
                                        option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                                        option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                    }>
                                    {taxonomyList.englishAllergens
                                        ? taxonomyList.englishAllergens.map((d, index) => {
                                              return (
                                                  <Option key={index} value={d.title}>
                                                      {d.title}
                                                  </Option>
                                              );
                                          })
                                        : null}
                                </Select>
                            </Form.Item>
                        </TabPane>
                    </Tabs>
                </Form>
            </Drawer>
        </>
    );
}

export default MenuItemForm;
