import React, { useContext, useEffect, useState } from 'react';
import { Layout, Menu, Breadcrumb, Row, Col, Dropdown } from 'antd';
import { DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { withAuthorization, AuthUserContext } from '../Session';
import * as ROUTES from '../../const/routes';
import AdminLayoutSidebar from './AdminLayoutSidebar';
import moment from 'moment';
import { useCompany, useRestaurant } from '../../swr';

// Recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
import { activeRestaurantState, companyDetailsState } from '../../recoil';

import { activeEnvironment } from '../../config';

const { Header, Content, Footer } = Layout;

const AdminLayout = (props) => {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    const { data: companyData, isLoading: companyIsLoading } = useCompany('', firebaseAuthUser.uid);
    const { data: restaurantsListNew, isLoading, isError } = useRestaurant(firebaseAuthUser.uid);

    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);
    const [companyDetails, setCompanyDetails] = useRecoilState(companyDetailsState);
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    const activeRestaurantLocalStorage = JSON.parse(localStorage.getItem(activeEnvironment + '_activeRestaurant')) || {};

    useEffect(() => {
        if (companyData && !companyData.message && isLoading !== true && restaurantsListNew.length > 0) {
            if (Object.keys(activeRestaurantLocalStorage).length === 0) {
                setActiveRestaurant({
                    title: restaurantsListNew[0].title,
                    uniqueIdentificationToken: restaurantsListNew[0].uniqueIdentificationToken,
                    subscriptionPlan: restaurantsListNew[0].subscriptionPlan,
                    sysRestaurantType: restaurantsListNew[0].sysRestaurantType,
                    activatePosIntegration: restaurantsListNew[0].activatePosIntegration,
                    logoPath: restaurantsListNew[0].logoPath,
                    coverImagePath: restaurantsListNew[0].coverImagePath,
                    modules: [...restaurantsListNew[0].modules]
                });
                localStorage.setItem(
                    activeEnvironment + '_activeRestaurant',
                    JSON.stringify({
                        title: restaurantsListNew[0].title,
                        uniqueIdentificationToken: restaurantsListNew[0].uniqueIdentificationToken,
                        subscriptionPlan: restaurantsListNew[0].subscriptionPlan,
                        sysRestaurantType: restaurantsListNew[0].sysRestaurantType,
                        activatePosIntegration: restaurantsListNew[0].activatePosIntegration,
                        logoPath: restaurantsListNew[0].logoPath,
                        coverImagePath: restaurantsListNew[0].coverImagePath,
                    })
                );
            } else {
                const restaurantMainDetails = restaurantsListNew.filter(
                    (d) => d.uniqueIdentificationToken === activeRestaurantLocalStorage.uniqueIdentificationToken
                )[0];
                if (restaurantMainDetails) {
                    setActiveRestaurant({
                        title: restaurantMainDetails.title,
                        uniqueIdentificationToken: restaurantMainDetails.uniqueIdentificationToken,
                        subscriptionPlan: restaurantMainDetails.subscriptionPlan,
                        sysRestaurantType: restaurantMainDetails.sysRestaurantType,
                        activatePosIntegration: restaurantMainDetails.activatePosIntegration,
                        logoPath: restaurantMainDetails.logoPath,
                        coverImagePath: restaurantMainDetails.coverImagePath,
                        modules: [...restaurantMainDetails.modules]
                    });
                }
            }
        }
        if (companyData && !companyData.message) {
            setCompanyDetails({...companyData})
        }
    }, [companyData, restaurantsListNew]);

    const handleChangeRestaurant = (restaurant) => {
        setActiveRestaurant({
            title: restaurant.title,
            uniqueIdentificationToken: restaurant.uniqueIdentificationToken,
            logoPath: restaurant.logoPath,
            coverImagePath: restaurant.coverImagePath,
            subscriptionPlan: restaurant.subscriptionPlan,
            sysRestaurantType: restaurant.sysRestaurantType,
            activatePosIntegration: restaurant.activatePosIntegration,
            modules: [...restaurant.modules]
        });

        localStorage.setItem(
            activeEnvironment + '_activeRestaurant',
            JSON.stringify({
                title: restaurant.title,
                uniqueIdentificationToken: restaurant.uniqueIdentificationToken,
                logoPath: restaurant.logoPath,
                coverImagePath: restaurant.coverImagePath,
                subscriptionPlan: restaurant.subscriptionPlan,
                sysRestaurantType: restaurant.sysRestaurantType,
                activatePosIntegration: restaurant.activatePosIntegration
            })
        );

        window.open(ROUTES.ADMIN, '_self');
    };

    const handleSignOut = () => {
        localStorage.clear();
        setActiveRestaurant({});
        setCompanyDetails({});
        props.firebase.doSignOut();
    };

    const menuLocations = (
        <Menu>
            {companyData && !companyData.message
                ? isLoading !== true
                    ? restaurantsListNew.length > 0
                        ? restaurantsListNew.map((d) => (
                              <Menu.Item key={d.uniqueIdentificationToken}>
                                  <a onClick={() => handleChangeRestaurant(d)} title={d.title}>
                                      {d.title}
                                  </a>
                              </Menu.Item>
                          ))
                        : null
                    : null
                : null}
            <Menu.Item key="allLocations">
                <Link to={ROUTES.ADMIN_LOCATIONS}>Administrare</Link>
            </Menu.Item>
        </Menu>
    );

    const menuAccount = (
        <Menu>
            <Menu.Item key="account">
                <Link to={ROUTES.ACCOUNT_SETTINGS}>Date cont</Link>
            </Menu.Item>
            <Menu.Item key="company">
                <Link to={ROUTES.ACCOUNT_COMPANY}>Date companie</Link>
            </Menu.Item>
            <Menu.Item key="password">
                <Link to={ROUTES.ACCOUNT_PW_CHANGE}>Modifica Parola</Link>
            </Menu.Item>
            <Menu.Item key="logout">
                <a onClick={handleSignOut}>Log out</a>
            </Menu.Item>
        </Menu>
    );

    const dropdownRestaurants =
        companyData && !companyData.message ? (
            isLoading !== true ? (
                <Dropdown overlay={menuLocations} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                        {activeRestaurantLocalStorage.title} <EllipsisOutlined style={{ fontSize: 18 }} />
                    </a>
                </Dropdown>
            ) : null
        ) : null;

    const dropdownAccount =
        isLoading !== true ? (
            <Dropdown overlay={menuAccount} trigger={['click']}>
                <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    {firebaseAuthUser ? firebaseAuthUser.email : ''} <DownOutlined />
                </a>
            </Dropdown>
        ) : null;

    return (
        <Layout style={{ minHeight: '100vh' }} className={props.className}>
            <AdminLayoutSidebar dropdownRestaurants={dropdownRestaurants} setSiderCollapsed={setSiderCollapsed} siderCollapsed={siderCollapsed} />
            <Layout className={'site-layout' + (siderCollapsed === true ? ' sider-collapsed' : '')}>
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    <Row justify="end">
                        <Col>
                            <div className="dropdownRestaurants">{dropdownAccount}</div>
                        </Col>
                    </Row>
                </Header>
                <Content style={{ margin: '0 16px' }}>
                    {props.breadcrumbVisible !== false ? (
                        <Breadcrumb style={{ margin: '16px 0' }}>
                            <Breadcrumb.Item>
                                <Link to={ROUTES.ADMIN}>Admin</Link>
                            </Breadcrumb.Item>
                            {props.breadcrumb
                                ? props.breadcrumb.map((b, index) => {
                                      return (
                                          <Breadcrumb.Item key={index}>
                                              <Link to={b.link}>{b.label}</Link>
                                          </Breadcrumb.Item>
                                      );
                                  })
                                : ''}
                            <Breadcrumb.Item>{props.pageTitle}</Breadcrumb.Item>
                        </Breadcrumb>
                    ) : null}
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        {props.children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>Poftigo © {moment().format('YYYY')}</Footer>
            </Layout>
        </Layout>
    );
};
const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(AdminLayout);
