import React, { useState } from 'react';
import { withAuthorization, AuthUserContext } from '../Session';
import { Input, Form, Button } from 'antd';
import AdminLayout from '../Admin/AdminLayout';

function ChangePassword(props) {

  const [form] = Form.useForm();
  const tailLayout = {
    wrapperCol: { offset: 3, span: 7 },
  };
  const INITIAL_STATE = {
    currentPW: '',
    newPW: '',
    repeatNewPW: '',
  };
  const [formData, setFormData] = useState({ ...INITIAL_STATE })
  const [error, setError] = useState("");


  const onChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  const handleUpdatePassword = (currentPassword, newPassword) => {
    props.firebase.doReauthenticate(currentPassword).then(() => {
      var user = props.firebase.auth.currentUser;
      user.updatePassword(newPassword).then(() => {
        console.log("Password updated!");
      }).catch((error) => { setError(error); });
    }).catch((error) => { setError(error); });
  }

  const onSubmit = () => {
    handleUpdatePassword(formData.currentPW, formData.newPW);
  }

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <AdminLayout authUser={authUser} pageTitle="Modifica parola">
          <h2>Modifica Parola</h2>
          {error && <p>{error.message}</p>}
          <Form layout="horizontal" form={form} labelCol={{ span: 3 }}
            wrapperCol={{ span: 7 }} size="large" onFinish={onSubmit}>
            <Form.Item
              name="currentPW"
              label="Parola actuala"
              rules={[{ required: true, message: 'Camp obligatoriu' }]}
            >
              <Input.Password onBlur={onChange} name="currentPW" />
            </Form.Item>
            <Form.Item
              name="newPW"
              label="Noua parola"
              rules={[{ required: true, message: 'Camp obligatoriu' }]}
            >
              <Input.Password onBlur={onChange} name="newPW" />
            </Form.Item>
            <Form.Item
              name="repeatNewPW"
              label="Repeta noua parola"
              rules={[{ required: true, message: 'Camp obligatoriu' }]}
            >
              <Input.Password onBlur={onChange} name="repeatNewPW" />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" className="btnAdd" htmlType="submit">
                Salveaza
          </Button>
            </Form.Item>
          </Form>

        </AdminLayout>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(ChangePassword);