import axios from 'axios';
import { bacchusAPIEndpoint, bacchusAPIUploadsPath } from '../config';
import moment from 'moment';

/* User functions */
export const getUser = (uid) => {
    return axios(bacchusAPIEndpoint + '/api/user/' + uid, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const updateUser = (userDetails) => {
    return axios(bacchusAPIEndpoint + '/api/user/', {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...userDetails,
        },
    });
};

export const postUser = (authUser, name, authenticationCode) => {
    return axios(bacchusAPIEndpoint + '/api/user', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            uuid: authUser.uid,
            userType: 'manager',
            active: 'true',
            name: name,
            emailAddress: authUser.email,
            authenticationCode: authenticationCode,
            agreedToGDPR: true,
            agreedToTermsAndConditions: true,
            agreedToPrivacyPolicy: true,
        },
    });
};

/* Company functions */
export const getCompanyDetails = (uid) => {
    return axios(bacchusAPIEndpoint + '/admin/company/getByUuid/' + uid, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const postCompanyDetails = (companyDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/company/', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...companyDetails,
        },
    });
};

export const updateCompanyDetails = (companyDetails, userUID) => {
    return axios(bacchusAPIEndpoint + '/admin/company/' + companyDetails.uniqueIdentificationToken + '/' + userUID, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...companyDetails,
        },
    });
};

/* Restaurant functions */

export const getRestaurantsInCompany = (companyUniqueIdentifier) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/getByCompany/' + companyUniqueIdentifier, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getRestaurantsByCode = (uniqueIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/' + uniqueIdentificationToken, {
        //return axios(bacchusAPIEndpoint + '/admin/restaurant/getRestaurantDetails/' + uniqueIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getRestaurantDetails = (uniqueIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/getRestaurantDetails/' + uniqueIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const postRestaurant = (restaurantDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...restaurantDetails,
        },
    });
};

export const updateRestaurant = (restaurantDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/' + restaurantDetails.uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...restaurantDetails,
        },
    });
};

// Tables
export const getRestaurantTables = (uniqueIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/table/getRestaurantTables/' + uniqueIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Insert table
export const postRestaurantTables = (restaurantTableDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/table', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...restaurantTableDetails,
        },
    });
};

// Update table
export const updateRestaurantTables = (restaurantTableDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/table/' + restaurantTableDetails.uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...restaurantTableDetails,
        },
    });
};

// Delete table
export const deleteRestaurantTable = (uniqueIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/table/' + uniqueIdentificationToken, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// HOTSPOTS

// GET Hotspots
export const getRestaurantHotspots = (uniqueIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/hotspot/getRestaurantHotspots/' + uniqueIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Insert hotspot
export const postRestaurantHotspot = (restaurantToken, details) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/hotspot/' + restaurantToken, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...details,
        },
    });
};

// Update hotspot
export const updateRestaurantHotspot = (restaurantToken, details) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/hotspot/' + restaurantToken + '/' + details.uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...details,
        },
    });
};

// Delete hotspot
export const deleteRestaurantHotspot = (restaurantToken, uniqueIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/hotspot/' + restaurantToken + '/' + uniqueIdentificationToken, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getRestaurantSchedule = (uniqueIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/getRestaurantSchedule/' + uniqueIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getRestaurantSchedules = (uniqueIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/getRestaurantSchedules/' + uniqueIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

//Events

export const postRestaurantEvent = (details) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/postEvent', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...details,
        },
    });
};

export const updateRestaurantEvent = (details) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/putEvent/', {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...details,
        },
    });
};

export const deleteRestaurantEvent = (uniqueIdentificationToken, restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/deleteEvent/' + uniqueIdentificationToken + '/' + restaurantIdentificationToken, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/* Offers / Promotions */

export const postRestaurantPromotion = (details) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/postPromotion', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...details,
        },
    });
};

export const updateRestaurantPromotion = (details) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/putPromotion/' + details.uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...details,
        },
    });
};

export const deleteRestaurantPromotion = (uniqueIdentificationToken, restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/deletePromotion/' + uniqueIdentificationToken + '/' + restaurantIdentificationToken, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/* Integrations */
export const getAvailableIntegrations = (restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/integrations/availableIntegrations/' + restaurantIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getIntegrationOperators = (restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/integrations/operators/' + restaurantIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getIntegrationTables = (restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/integrations/tables/' + restaurantIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getIntegrationProducts = (restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/integrations/products/' + restaurantIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const syncIntegration = (restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/integrations/sync/' + restaurantIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/* Menus functions */
export const getMenuByRestaurant = (restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/getByRestaurant/' + restaurantIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Get Menu by UID
export const getMenuByUniqueCode = (restaurantIdentificationToken, menuIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/getByUniqueCode/' + restaurantIdentificationToken + '/' + menuIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Create Menu
export const postMenu = (menuDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/menu', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...menuDetails,
        },
    });
};

// Update Menu
export const updateMenu = (menuDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/' + menuDetails.uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...menuDetails,
        },
    });
};

// Bulk update menus
export const updateMenus = (menus, restaurantToken) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menus/' + restaurantToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: [...menus],
    });
};

// Delete Menu
export const deleteMenu = (uniqueIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/' + uniqueIdentificationToken, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Get Menu types
export const getMenuTypes = () => {
    return axios(bacchusAPIEndpoint + '/admin/menu/getMenuTypes', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Change Menu state
export const changeMenuState = (restaurantToken, menuToken, active) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/changeMenuState/' + restaurantToken + '/' + menuToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            uniqueIdentificationToken: menuToken,
            active: active,
        },
    });
};

// Get Menu items
export const getMenuItems = (menuIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/getMenuItems/' + menuIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Get Menu item
export const getMenuItem = (menuItemToken, restaurantToken) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/getMenuItem/' + menuItemToken + '/' + restaurantToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Get Menu item
export const getMenuItemForDisplay = (menuItemToken, restaurantToken) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/getMenuItemForDisplay/' + menuItemToken + '/' + restaurantToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Create Menu item
export const postMenuItem = (menuItemDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menuItem', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...menuItemDetails,
        },
    });
};

// Update Menu item
export const updateMenuItem = (menuDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menuItem/' + menuDetails.uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...menuDetails,
        },
    });
};

// Change Menu item state
export const changeMenuItemState = (restaurantToken, menuItemDetails, active) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/changeMenuItemState/' + restaurantToken + '/' + menuItemDetails.uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            menuIdentificationToken: menuItemDetails.menuIdentificationToken,
            menuCategoryToken: menuItemDetails.menuCategoryIdentificationToken,
            uniqueIdentificationToken: menuItemDetails.uniqueIdentificationToken,
            active: active,
        },
    });
};

// Delete Menu item
export const deleteMenuItem = (menuItemToken, restaurantToken) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menuItem/' + menuItemToken + '/' + restaurantToken, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Update Menu items - Bulk
export const updateMenuItems = (menuDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menuItems/', {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: [...menuDetails],
    });
};

// Get Menu items by Restaurant
export const getMenuItemsByRestaurant = (restaurantId) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/getMenuItemsByRestaurant/' + restaurantId, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Get Menu item groups
export const getMenuItemGroup = (restaurantId) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/getMenuItemGroup/' + restaurantId, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Create Menu item group
export const postMenuItemGroup = (data) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menuItemGroup', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...data,
        },
    });
};

// Update Menu item group
export const updateMenuItemGroup = (data) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menuItemGroup/' + data.uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...data,
        },
    });
};

// Delete Menu item group
export const deleteMenuItemGroup = (uniqueIdentificationToken, restaurantToken) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menuItemGroup/' + uniqueIdentificationToken + '/' + restaurantToken, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Get Taxonomy
export const getMenuTaxonomy = (taxonomy, keyword, languageCode = 'ro_RO') => {
    let methodName = '';
    switch (taxonomy) {
        case 'ingredients':
        case 'englishIngredients':
            methodName = 'getIngredients';
            break;
        case 'tags':
            methodName = 'getTags';
            break;
        case 'allergens':
            methodName = 'getAllergens';
            break;
        case 'foodAdditives':
            methodName = 'getFoodAdditives';
            break;
        case 'englishAllergens':
            methodName = 'getAllergensinEnglish';
            languageCode = '';
            break;
    }
    if (keyword == '') keyword = 'null';
    return axios(bacchusAPIEndpoint + '/admin/menu/' + methodName + '/' + keyword + '/' + languageCode, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Get Ingredients
export const getMenuIngredients = (keyword, restaurantId, languageCode = 'ro_RO') => {
    return axios(bacchusAPIEndpoint + '/admin/menu/getIngredients/' + keyword + '/' + restaurantId, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getMenuIngredientsFromInfoCons = (keyword) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/getIngredientsFromInfoCons/' + keyword, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const postMenuTaxonomy = (taxonomy, taxonomyItem, languageCode = 'ro_RO') => {
    return axios(bacchusAPIEndpoint + '/admin/menu/postIngredient', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            title: taxonomyItem,
            languageCode: languageCode,
        },
    });
};

// Post Ingredients

export const postMenuIngredient = (data) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/postIngredient', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...data,
        },
    });
};

export const updateMenuIngredient = (data) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/putIngredient', {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...data,
        },
    });
};

// Get Menu categories by menu
export const getMenuCategories = (menuIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/getMenuCategories/' + menuIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Post Menu Category
export const postMenuCategory = (menuCategoryDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menuCategory', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...menuCategoryDetails,
        },
    });
};

// Bulk update menu categories
export const updateMenuCategories = (menuCategories) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menuCategories/', {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: [...menuCategories],
    });
};

// Update Menu category
export const updateMenuCategory = (menuCategoryDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menuCategory/' + menuCategoryDetails.uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...menuCategoryDetails,
        },
    });
};

// Change Menu category state
export const changeMenuCategoryState = (restaurantToken, menuCategoryDetails, active) => {
    return axios(
        bacchusAPIEndpoint + '/admin/menu/changeMenuCategoryState/' + restaurantToken + '/' + menuCategoryDetails.uniqueIdentificationToken,
        {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                menuIdentificationToken: menuCategoryDetails.menuIdentificationToken,
                uniqueIdentificationToken: menuCategoryDetails.uniqueIdentificationToken,
                active: active,
            },
        }
    );
};

// Delete Menu category
export const deleteMenuCategory = (menuCategoryToken, restaurantToken) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/menuCategory/' + menuCategoryToken + '/' + restaurantToken, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const postMenuCategoryAsMenuItemGroup = (restaurantToken, menuCategoryDetails, keepPrices = true) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/postCategoryAsMenuItemGroup/' + restaurantToken + '/' + keepPrices, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...menuCategoryDetails,
        },
    });
};

export const putMenuCategoryAsMenuItemGroup = (restaurantToken, menuCategoryToken, keepPrices = true) => {
    return axios(bacchusAPIEndpoint + '/admin/menu/putCategoryAsMenuItemGroup/' + restaurantToken + '/' + menuCategoryToken + '/' + keepPrices, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Uploads and media
export const uploadRestaurantLogo = (restaurantIdentificationToken) => {
    return bacchusAPIEndpoint + '/admin/image/restaurant/PutLogo/' + restaurantIdentificationToken;
};

export const uploadRestaurantCover = (restaurantIdentificationToken) => {
    return bacchusAPIEndpoint + '/admin/image/restaurant/PutCoverPhoto/' + restaurantIdentificationToken;
};

export const getImageLink = (imagePath) => {
    return bacchusAPIUploadsPath + imagePath.replace('Images\\', '').replace('\\', '/');
};

export const getImageLinkSize = (imagePath, size) => {
    let [filename, extension] = (bacchusAPIUploadsPath + imagePath.replace('Images\\', '')).split('.jpg');
    let rename = filename + '-' + size + '.jpg';
    return rename;
};

export const uploadMenuCategoryImage = (uniqueIdentificationToken, restaurantIdentificationToken) => {
    return bacchusAPIEndpoint + '/admin/image/menu/PutMenuCategoryImage/' + uniqueIdentificationToken + '/' + restaurantIdentificationToken;
};

export const uploadMenuItemImage = (uniqueIdentificationToken, restaurantIdentificationToken) => {
    return bacchusAPIEndpoint + '/admin/image/menu/PutMenuItemImage/' + uniqueIdentificationToken + '/' + restaurantIdentificationToken;
};

export const uploadEventImage = (uniqueIdentificationToken, restaurantIdentificationToken) => {
    return bacchusAPIEndpoint + '/admin/image/event/putEventImage/' + restaurantIdentificationToken + '/' + uniqueIdentificationToken;
};

export const uploadPromotionImage = (uniqueIdentificationToken, restaurantIdentificationToken) => {
    return bacchusAPIEndpoint + '/admin/image/promotion/putPromotionImage/' + restaurantIdentificationToken + '/' + uniqueIdentificationToken;
};

export const deleteRestaurantLogo = (restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/image/restaurant/DeleteLogo/' + restaurantIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {},
    });
};

export const deleteRestaurantCoverImage = (restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/image/restaurant/DeleteCoverImage/' + restaurantIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {},
    });
};

export const deleteMenuCategoryImage = (uniqueIdentificationToken, restaurantIdentificationToken) => {
    return axios(
        bacchusAPIEndpoint + '/admin/image/menu/DeleteMenuCategoryImage/' + uniqueIdentificationToken + '/' + restaurantIdentificationToken,
        {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {},
        }
    );
};

export const deleteMenuItemImage = (uniqueIdentificationToken, restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/image/menu/DeleteMenuItemImage/' + uniqueIdentificationToken + '/' + restaurantIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {},
    });
};

export const deleteEventImage = (uniqueIdentificationToken, restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/image/event/DeleteEventImage/' + restaurantIdentificationToken + '/' + uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {},
    });
};

export const deletePromotionImage = (uniqueIdentificationToken, restaurantIdentificationToken) => {
    return axios(
        bacchusAPIEndpoint + '/admin/image/promotion/DeletePromotionImage/' + restaurantIdentificationToken + '/' + uniqueIdentificationToken,
        {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {},
        }
    );
};

// Common methods
export const getUUID = () => {
    return axios(bacchusAPIEndpoint + '/admin/common/getUuid', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getCuisineTypes = () => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/getCuisineTypes/', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const validateAuthenticationCode = (code) => {
    return axios(bacchusAPIEndpoint + '/admin/common/validateAuthenticationCode/' + code, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Admin -> Reports -> Feedback history by restaurant
export const getFeedbackHistoryByRestaurant = (
    restaurantIdentificationToken,
    startDate = moment().format('YYYY-MM-DD'),
    endDate = moment().format('YYYY-MM-DD')
) => {
    return axios(
        bacchusAPIEndpoint + '/api/reports/getFeedbackHistoryByRestaurant/' + restaurantIdentificationToken + '/' + startDate + '/' + endDate,
        {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
};

// Admin -> Reports -> Waiters Info by restaurant
export const getWaitersInfoByRestaurant = (restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/api/reports/getWaitersInfoByRestaurant/' + restaurantIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Admin -> Restaurant -> Waiters
export const getPendingValidationWaiters = (restaurantIdentificationToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/getPendingValidationWaiters/' + restaurantIdentificationToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Admin -> Restaurant -> Waiters -> ValidateWaiter
export const validateWaiter = (uniqueIdentificationToken, validationDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/validateWaiter/' + uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...validationDetails,
        },
    });
};
// Update Waiter
export const updateWaiter = (waiterDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/putWaiter/' + waiterDetails.uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...waiterDetails,
        },
    });
};
// Delete Waiter
export const deleteWaiter = (restaurantIdentificationToken, waiterDetails) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/waiter/' + restaurantIdentificationToken + '/' + waiterDetails.uuid, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Get Order
export const getOrder = (tableSessionToken, restaurantToken) => {
    return axios(bacchusAPIEndpoint + '/api/user/getOrder/' + tableSessionToken + '/' + restaurantToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// CUSTOMIZATIONS
// GET Customization
export const getRestaurantCustomization = (restaurantToken) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/customization/' + restaurantToken, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// Update Customization
export const postRestaurantCustomization = (data) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/customization/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...data,
        },
    });
};

// Update Customization
export const updateRestaurantCustomization = (uniqueIdentificationToken, data) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/customization/' + uniqueIdentificationToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...data,
        },
    });
};

// Upload Customization image
export const uploadRestaurantCustomizationImageQR = (restaurantToken) => {
    return bacchusAPIEndpoint + '/admin/image/restaurant/customization/qrImage/' + restaurantToken;
};

// Delete Customization image
export const deleteRestaurantCustomizationImageQR = (restaurantToken) => {
    return axios(bacchusAPIEndpoint + '/admin/image/restaurant/customization/deleteQrImage/' + restaurantToken, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {},
    });
};

export { bacchusAPIUploadsPath };
