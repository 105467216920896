import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../const/routes';
import { Row, Col, Form, Input, Button, Alert, message, Checkbox } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, BarcodeOutlined } from '@ant-design/icons';
import AuthLayout from '../Layout/AuthLayout';
import { ApiHelpers } from '../../helpers';
import logo from '../../assets/img/poftigo-admin-logo-tr.png';
import ReCAPTCHA from 'react-google-recaptcha';
import withRouter from '../Session/withRouter';

const SignUpPage = () => (
    <AuthUserContext.Consumer>
        {(authUser) =>
            !authUser ? (
                <AuthLayout>
                    <Row>
                        <Col
                            xs={{ span: 22, offset: 1 }}
                            sm={{ span: 16, offset: 4 }}
                            md={{ span: 12, offset: 6 }}
                            lg={{ span: 10, offset: 7 }}
                            xl={{ span: 8, offset: 8 }}
                            xxl={{ span: 6, offset: 9 }}>
                            <div className="authFormContainer">
                                <div className="authLogo">
                                    <img src={logo} title="Pofitgo Admin" alt="Poftigo Admin" />
                                </div>
                                <h1 className="page-title">Fa-ti cont in Poftigo Admin</h1>
                                <SignUpForm />
                            </div>
                        </Col>
                    </Row>
                </AuthLayout>
            ) : (
                <Navigate to={ROUTES.ADMIN} replace={true} />
            )
        }
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    username: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
    signUpCode: '',
};

const recaptchaRef = React.createRef();

class SignUpFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = (event) => {
        const { signUpCode, username, email, passwordOne, passwordTwo } = this.state;
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue) {
            ApiHelpers.validateAuthenticationCode(signUpCode).then(
                (response) => {
                    if (response.data.statusCode === 200) {
                        this.props.firebase
                            .doCreateUserWithEmailAndPassword(email, passwordOne)
                            .then((authUser) => {
                                this.props.firebase.doSendEmailVerification();
                                ApiHelpers.postUser(authUser.user, username, signUpCode).then(
                                    (response) => {
                                        //this.setState({ ...INITIAL_STATE });
                                        this.props.router.navigate(ROUTES.ADMIN);
                                    },
                                    (error) => {
                                        console.log(error);
                                    }
                                );
                            })

                            .catch((error) => {
                                this.setState({ error });
                            });
                    } else {
                        message.error('Codul nu este valid!');
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        } else {
            message.error('Codul de siguranta nu este completat');
        }
        //event.preventDefault();
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeCaptcha = (value) => {
        console.log('Captcha value:', value);
    };

    render() {
        const { username, email, passwordOne, passwordTwo, error, signUpCode } = this.state;

        const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || email === '' || username === '';

        return (
            <div>
                {error && <Alert type="error" message={error.message} />}
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.onSubmit}>
                    <Form.Item
                        name="signUpCode"
                        rules={[
                            {
                                required: true,
                                message: 'Codul este camp obligatoriu',
                            },
                        ]}>
                        <Input
                            size="large"
                            prefix={<BarcodeOutlined className="site-form-item-icon" />}
                            onChange={this.onChange}
                            value={signUpCode}
                            name="signUpCode"
                            placeholder="Cod inregistrare"
                        />
                    </Form.Item>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Numele este camp obligatoriu',
                            },
                        ]}>
                        <Input
                            size="large"
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            onChange={this.onChange}
                            value={username}
                            name="username"
                            placeholder="Nume"
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'Email este camp obligatoriu',
                            },
                        ]}>
                        <Input
                            size="large"
                            prefix={<MailOutlined className="site-form-item-icon" />}
                            onChange={this.onChange}
                            value={email}
                            name="email"
                            placeholder="Adresa de email"
                        />
                    </Form.Item>
                    <Form.Item
                        name="passwordOne"
                        rules={[
                            {
                                required: true,
                                message: 'Parola este camp obligatoriu',
                            },
                        ]}>
                        <Input
                            size="large"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            onChange={this.onChange}
                            value={passwordOne}
                            name="passwordOne"
                            placeholder="Parola"
                        />
                    </Form.Item>
                    <Form.Item
                        name="passwordTwo"
                        rules={[
                            {
                                required: true,
                                message: 'Parola este camp obligatoriu',
                            },
                        ]}>
                        <Input
                            size="large"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            onChange={this.onChange}
                            value={passwordTwo}
                            name="passwordTwo"
                            placeholder="Repeta parola"
                        />
                    </Form.Item>
                    <Form.Item
                        name="agreedToTermsAndConditions"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Campul este obligatoriu'))),
                            },
                        ]}>
                        <Checkbox name="agreedToTermsAndConditions">
                            Sunt de acord cu{' '}
                            <a href="https://www.poftigo.ro/termeni-de-utilizare" target="blank">
                                Termenii de utilizare
                            </a>{' '}
                            si{' '}
                            <a href="https://www.poftigo.ro/politica-de-confidentialitate/" target="blank">
                                Politica de confidentialitate
                            </a>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <ReCAPTCHA ref={recaptchaRef} sitekey="6LcJuPocAAAAADSq0qRTC18n1sQCIsgsJhEidQxm" />
                    </Form.Item>
                    <Form.Item>
                        <Button size="large" type="primary" htmlType="submit" className="login-form-button">
                            Inregistrare
                        </Button>
                    </Form.Item>
                    {error && <p>{error.message}</p>}
                    Ai deja cont? <Link to={ROUTES.SIGN_IN}>Intra in cont</Link>
                </Form>
            </div>
        );
    }
}

const SignUpLink = () => (
    <p>
        Don't have an account? <Link to={ROUTES.SIGN_UP}>Inregistreaza</Link>
    </p>
);

const SignUpForm = withFirebase(withRouter(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
