import React, { useState, useEffect } from 'react';
import {
    Table,
    Input,
    Form,
    Drawer,
    Button,
    message,
    Checkbox,
    Upload,
    Menu,
    Select,
    Row,
    Col,
    Tabs,
    Popconfirm,
    Popover,
    Collapse,
    Switch,
} from 'antd';
import {
    PlusOutlined,
    CheckOutlined,
    EditOutlined,
    UnorderedListOutlined,
    MenuOutlined,
    NodeExpandOutlined,
    CheckCircleOutlined,
} from '@ant-design/icons';
import { useLocation, Link } from 'react-router-dom';
import { ApiHelpers } from '../../../helpers';
import UploadCategoryImage from './UploadCategoryImage';
import * as ROUTES from '../../../const/routes';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const Search = Input.Search;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const SortableItem = sortableElement((props) => <tr {...props} className="ant-table-row" />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

function MenuCategoryForm(props) {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [formMenuCategory] = Form.useForm();
    const [newElement, setNewElement] = useState([]);
    const [actionType, setActionType] = useState(props.actionType);
    const { menuCategoriesData, setMenuCategoriesData } = props;
    const [menuCategoriesDataFilter, setMenuCategoriesDataFilter] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState();

    let routeLocation = useLocation();

    const callbackTabs = (key) => {
        setActiveTabKey(key);
    };
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(menuCategoriesData), oldIndex, newIndex).filter((el) => !!el);
            newData.map((d, index) => (d.categoryIndex = index));

            ApiHelpers.updateMenuCategories(newData).then(
                (response) => {
                    message.success('Modificari efectuate!');
                    setMenuCategoriesData(newData);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };

    const DraggableContainer = (props) => (
        <SortableContainer useDragHandle disableAutoscroll helperClass="rowDragging" onSortEnd={onSortEnd} {...props} />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = menuCategoriesData.findIndex((x) => x.uniqueIdentificationToken === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const uploadPropsImage = (uniqueIdentificationToken) => {
        return {
            name: 'file',
            action: ApiHelpers.uploadMenuCategoryImage(uniqueIdentificationToken, props.menuData.restaurantIdentificationToken),
            headers: {
                authorization: 'authorization-text',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    // console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                    ApiHelpers.getMenuCategories(props.menuId).then((response) => {
                        setMenuCategoriesData(response.data);
                        const elementsIndex = menuCategoriesData.findIndex(
                            (element) => element.uniqueIdentificationToken === newElement.uniqueIdentificationToken
                        );
                        let newMenusList = [...menuCategoriesData];
                        console.log(newMenusList[elementsIndex]);
                        setNewElement({ ...newElement, categoryImagePath: response.data[elementsIndex].categoryImagePath });
                    });
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
    };
    const INITIAL_STATE_ELEMENT_CATEGORY = {
        menuIdentificationToken: props.menuId,
        uniqueIdentificationToken: null,
        title: '',
        categoryImagePath: null,
        active: false,
        tag: null,
        englishTitle: '',
        asMenuItemGroupKeepActive: false,
        integrationCode: ''
    };

    const getMenuCategories = () => {
        // Get Menu categories through API
        ApiHelpers.getMenuCategories(props.menuId).then(
            (response) => {
                setMenuCategoriesData(response.data);
                setMenuCategoriesDataFilter(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        // First get menu categories
        getMenuCategories();
    }, []);

    const onClose = () => {
        setDrawerVisible(false);
        onReset();
        setActiveTabKey('ro');
    };
    const onAddElement = (element) => {
        setActionType('new');
        // Get generated UUID from API and store it in state
        ApiHelpers.getUUID().then(
            (response) => {
                setNewElement({
                    ...INITIAL_STATE_ELEMENT_CATEGORY,
                    uniqueIdentificationToken: response.data.uuid,
                });
                setDrawerVisible(true);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const onEditElement = (record, contentType) => {
        setActionType('edit');
        // Set form fields values
        const editObject = {
            ...record,
        };
        formMenuCategory.setFieldsValue({ ...editObject });
        // Update state with the element being edited
        setNewElement({ ...editObject });
        setDrawerVisible(true);
    };

    const onSave = () => {
        // Validate form
        formMenuCategory
            .validateFields()
            .then(() => {
                if (actionType === 'new') {
                    ApiHelpers.postMenuCategory(newElement).then(
                        (response) => {
                            if (response.data.message) {
                                message.error('Eroare sursa de date.');
                            } else {
                                const newList = menuCategoriesData.concat(response.data.menuCategory);
                                setMenuCategoriesData(newList);
                                message.success('Element adaugat!');
                            }
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                } else if (actionType === 'edit') {
                    ApiHelpers.updateMenuCategory(newElement).then(
                        (response) => {
                            if (response.data.message) {
                                message.error('Eroare sursa de date.');
                            } else {
                                const elementsIndex = menuCategoriesData.findIndex(
                                    (element) => element.uniqueIdentificationToken === newElement.uniqueIdentificationToken
                                );
                                let newMenusList = [...menuCategoriesData];
                                newMenusList[elementsIndex] = { ...newElement };
                                setMenuCategoriesData(newMenusList);
                                message.success('Element modificat!');
                            }
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                }
                onReset();
                setDrawerVisible(false);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const onDelete = () => {
        ApiHelpers.deleteMenuCategory(newElement.uniqueIdentificationToken, props.menuData.restaurantIdentificationToken).then(
            (response) => {
                if (response.data.statusCode.statusCode === 200) {
                    const newList = menuCategoriesData.filter(function (obj) {
                        return obj.uniqueIdentificationToken !== newElement.uniqueIdentificationToken;
                    });
                    setMenuCategoriesData(newList);
                    setMenuCategoriesDataFilter(newList);
                    message.success('Elementul a fost sters!');
                }
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
        onReset();
        setDrawerVisible(false);
    };

    const onChange = (event) => {
        setNewElement({
            ...newElement,
            [event.target.name]: event.target.value,
        });
    };

    const onChangeCheckbox = (event, field) => {
        setNewElement({
            ...newElement,
            [field]: event.target.checked,
        });
    };

    const onChangeSelect = (value) => {
        setNewElement({
            ...newElement,
            tag: value,
        });
    };

    const onReset = () => {
        setNewElement([]);
        formMenuCategory.resetFields();
    };

    const onDeleteMenuCategoryImage = (uniqueIdentificationToken, restaurantIdentificationToken) => {
        ApiHelpers.deleteMenuCategoryImage(uniqueIdentificationToken, restaurantIdentificationToken).then(
            (response) => {
                setNewElement({
                    ...newElement,
                    categoryImagePath: null,
                });
                message.success('Imagine stearsa!');
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
    };

    const menuImg = (uniqueIdentificationToken) => {
        return (
            <Menu>
                <Menu.Item>
                    <a onClick={() => onDeleteMenuCategoryImage(uniqueIdentificationToken, props.menuData.restaurantIdentificationToken)} href="#">
                        Sterge
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <Upload {...uploadPropsImage(uniqueIdentificationToken)}>
                        <a href="#">Modifica</a>
                    </Upload>
                </Menu.Item>
            </Menu>
        );
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setMenuCategoriesData(
                menuCategoriesDataFilter.filter((item) => {
                    if (item.title.toLowerCase().indexOf(value) > -1) return true;
                })
            );
        } else setMenuCategoriesData(menuCategoriesDataFilter);
    };

    const handleMenuCategoryAsMenuItemGroup = () => {
        ApiHelpers.postMenuCategoryAsMenuItemGroup(
            props.menuData.restaurantIdentificationToken,
            newElement,
            newElement.asMenuItemGroupKeepActive || false
        ).then(
            (response) => {
                if (response.data.message) {
                    message.error('Eroare sursa de date.');
                } else {
                    message.success('Group creat!');
                    setNewElement({ ...newElement, menuItemGroupToken: response.data.menuItemGroup.uniqueIdentificationToken });
                }
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
    };

    const columns = [
        {
            title: 'Sortare',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Imagine',
            dataIndex: 'itemImage',
            className: 'drag-visible',
            width: '150px',
            render: (text, record) =>
                record.categoryImagePath !== '' && record.categoryImagePath !== null ? (
                    <img alt="" title="" src={ApiHelpers.getImageLink(record.categoryImagePath)} className="thumbnailList" />
                ) : (
                    ''
                ),
        },
        {
            title: 'Denumire',
            dataIndex: 'title',
            className: 'drag-visible',
        },
        {
            title: 'Actiuni',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Button type="default" className="tableAction" icon={<EditOutlined />} onClick={() => onEditElement(record, 'menuCategory')}>
                        Editează
                    </Button>
                    <Button
                        type="default"
                        className="tableAction"
                        icon={<UnorderedListOutlined />}
                        onClick={() => props.setActiveTabKey('#items')}
                        href={routeLocation.pathname + '#items?category=' + record.uniqueIdentificationToken}>
                        Vezi preparate
                    </Button>
                </div>
            ),
        },
        {
            title: 'Tip',
            datIndex: 'tag',
            key: 'tag',
            render: (record) => (record.tag === 1 ? 'Mancare' : record.tag === 2 ? 'Bauturi' : record.tag === 3 ? 'Tutun' : null),
        },
        {
            title: 'Grup auxiliar',
            dataIndex: 'menuItemGroupToken',
            render: (text, record) => (record.menuItemGroupToken ? <CheckOutlined /> : ''),
        },
        {
            title: 'Activ',
            dataIndex: 'active',
            render: (text, record) => <Switch defaultChecked={record.active} onChange={(checked) => onChangeSwitchActive(checked, record)} />,
        },
    ];

    const onChangeSwitchActive = (checked, record) => {
        ApiHelpers.changeMenuCategoryState(props.menuData.restaurantIdentificationToken, record, checked).then(
            (response) => {
                if (response.data.message) {
                    message.error('Eroare sursa de date.');
                } else {
                    message.success('Element modificat!');
                    getMenuCategories();
                }
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
    };

    return (
        <>
            <Row justify="space-between">
                <Col>
                    <Button type="primary" onClick={() => onAddElement('menuCategory')} className="btnAdd">
                        <PlusOutlined /> Adauga categorie
                    </Button>
                </Col>
                <Col>
                    <Search placeholder="Cauta" onChange={handleSearch} className="tableSearchField" />
                </Col>
            </Row>
            <Table
                pagination={false}
                dataSource={menuCategoriesData}
                columns={columns}
                scroll={{ x: 240 }}
                rowKey="uniqueIdentificationToken"
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
            />
            <Drawer
                title="Categorie meniu"
                width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                onClose={onClose}
                visible={drawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div className="modalFooter">
                        <div
                            style={{
                                textAlign: 'left',
                            }}>
                            {actionType !== 'new' ? (
                                <Popconfirm title="Sigur vrei sa stergi?" onConfirm={onDelete}>
                                    <Button size="large" danger style={{ marginRight: 8 }}>
                                        Sterge
                                    </Button>
                                </Popconfirm>
                            ) : null}
                        </div>
                        <div
                            style={{
                                textAlign: 'right',
                            }}>
                            <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                Anuleaza
                            </Button>
                            <Button size="large" onClick={onSave} type="primary">
                                Salveaza
                            </Button>
                        </div>
                    </div>
                }>
                <Form layout="vertical" form={formMenuCategory} size="large">
                    <Tabs defaultActiveKey="ro" type="card" activeKey={activeTabKey} onChange={callbackTabs}>
                        <TabPane tab="RO" key="ro">
                            <Collapse className="formCollapse" bordered={false} defaultActiveKey={['info', 'media']}>
                                <Panel header="Informatii" key="info">
                                    <Form.Item name="tag" label="Tip" rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                                        <Select size="large" placeholder="Selecteaza tip" onChange={onChangeSelect} name="tag" value={newElement.tag}>
                                            <Option key="mancare" value={1}>
                                                Mancare
                                            </Option>
                                            <Option key="bauturi" value={2}>
                                                Bauturi
                                            </Option>
                                            <Option key="tutun" value={3}>
                                                Tutun
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="title" label="Denumire" rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                                        <Input placeholder="Please enter name" onBlur={onChange} name="title" value={newElement.title} />
                                    </Form.Item>

                                    <Checkbox checked={newElement.active} onChange={(event) => onChangeCheckbox(event, 'active')}>
                                        Activ
                                    </Checkbox>
                                </Panel>
                                <Panel header="Imagini" key="media">
                                    <Form.Item name="categoryImagePath" label="Imagine" rules={[{ required: false, message: 'Camp obligatoriu' }]}>
                                        <UploadCategoryImage
                                            menuCategoriesData={menuCategoriesData}
                                            setMenuCategoriesData={setMenuCategoriesData}
                                            getMenuCategories={getMenuCategories}
                                            menuCategoryId={newElement.uniqueIdentificationToken}
                                            newElement={newElement}
                                            setNewElement={setNewElement}
                                            restaurantIdentificationToken={props.menuData.restaurantIdentificationToken}
                                            menuId={props.menuId}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Grup auxiliar" key="menuItemGroup">
                                    {newElement.menuItemGroupToken ? (
                                        <Row align="middle" gutter={[16, 16]}>
                                            <Col>
                                                <Link
                                                    className="auxItemLink"
                                                    to={
                                                        ROUTES.ADMIN_LOCATIONS +
                                                        props.menuData.restaurantIdentificationToken +
                                                        '/menus#menuItemGroups/menuItemGroup/' +
                                                        newElement.menuItemGroupToken
                                                    }>
                                                    <CheckCircleOutlined twoToneColor="#52c41a" style={{ fontSize: 16 }} />
                                                    Grup creat
                                                </Link>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <>
                                            <Popover
                                                placement="right"
                                                content={
                                                    <>
                                                        <Checkbox
                                                            checked={newElement.asMenuItemGroupKeepActive}
                                                            onChange={(event) => onChangeCheckbox(event, 'asMenuItemGroupKeepActive')}>
                                                            Pastreaza preturile preparatelor
                                                        </Checkbox>
                                                        <Button
                                                            className="popconfirmButton"
                                                            size="small"
                                                            type="primary"
                                                            onClick={handleMenuCategoryAsMenuItemGroup}>
                                                            Ok
                                                        </Button>
                                                    </>
                                                }
                                                trigger="click">
                                                <Button size="large">
                                                    <NodeExpandOutlined /> Transforma in grup auxiliar
                                                </Button>
                                            </Popover>
                                        </>
                                    )}
                                </Panel>
                            </Collapse>
                        </TabPane>
                        <TabPane tab="EN" key="en">
                            <Form.Item name="englishTitle" label="Denumire" rules={[{ required: false, message: 'Camp obligatoriu' }]}>
                                <Input placeholder="Please enter name" onBlur={onChange} name="englishTitle" value={newElement.englishTitle} />
                            </Form.Item>
                        </TabPane>
                    </Tabs>
                </Form>
            </Drawer>
        </>
    );
}

export default MenuCategoryForm;
